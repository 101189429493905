import { Controller } from 'stimulus'
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper'

Swiper.use([Navigation, Pagination, EffectFade])

export default class extends Controller {

  connect() {
    this.initGallerySlider()
  }

  initGallerySlider() {
    new Swiper('.js-gallerySlider', {
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      speed: 800,
      threshold: 35,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        700: {
          threshold: 70,
        },
        1024: {
          threshold: 100,
        },
        1280: {
          threshold: 150,
        },
      }
    })
  }

}
